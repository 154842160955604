@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.background{
  z-index: -30;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-image: url("../public/LangeJaap.jpg");
  background-position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.6;
}

.titlepage-text{
  position: relative;
}

.titlepage-text::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  background: yellow;
  bottom: -10%;
  left: 50%;
  transform: translateX(-50%);
  animation: titleanim 1s forwards 0.5s;
}

.paul-card {
  border-radius: 15px;
    transform-origin: left bottom;
    transform: rotate(-45deg);
    opacity: 0;
    margin-left: 6rem;
    margin: 50px;
    max-width: 20%;
    aspect-ratio: 1/1.2;
    overflow: hidden;
  animation: paul-card-anim 0.6s ease forwards;
}

.paul-card2 {
  border-radius: 15px;
  transform-origin: right bottom;
  transform: rotate(-45deg);
  opacity: 0;
  margin-left: 6rem;
  margin: 50px;
  max-width: 20%;
  aspect-ratio: 1/1.2;
  overflow: hidden;
  
  animation: paul-card-anim2 0.6s ease forwards 0.2s;
}

.header-mobile-link {
  display: none;
}

@media screen and (max-width: 768px) {
  .header-link {
    display: none;
  }
  .header-mobile-link {
    display: block;
  }
  .paul-card{
    max-width: 45%;
  }
  .paul-card2{
    max-width: 45%;
  }
}

@media screen and (max-width: 950px) {
  .paul-card {
      margin-right: 2rem;
    }
  .paul-card2 {
    margin-left: 2rem;
  }
}

.itemwrapbox {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;

}

.reviewswrapbox {
  width: 84%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .reviewswrapbox{
    justify-content: center;
  }
  .itemwrapbox{
    justify-content: center;
  }
}

.itemwrap{
  display: flex;
    max-width: 22rem;
    width: 70%;
    opacity: 1;
    margin: 1rem;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  
}

.itemwrapimagewrap{
  max-width: 22rem;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 3/2;
}

.itemwrapimage{
  overflow: hidden;
  width: 22rem;
  aspect-ratio: 3/2;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -69;
}

.itemwrap:hover > .itemwrapimagewrap >  .itemwrapimage{
  scale: 1.1;
  left: 55%;
  top: 55%;
  transition: all 0.3s;
}


.contact-form > form{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 75%;
}

.contact-form>form>input{
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
}

.contact-form>form>input:focus {
  outline: none !important;
    border: 1px solid #fcd805;
}

.contact-form>form>textarea {
  padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
}

.contact-form>form>textarea:focus {
  outline: none !important;
    border: 1px solid #fcd805;
}

.contact-form>form>button:hover {
  box-shadow: 0px 0px 5px #fcd805;
  transform: translateY(-2px);
}

.contact-a:hover{
  text-decoration: underline;
}

.chevrondownhome{
  animation: chevron 2s linear infinite alternate;
  transition: 0.5s all;
}

@keyframes newpopin{
  100%{
    height: 20rem;
    width: 25rem;
    opacity: 1;
  }
}

@keyframes titleanim {
  to {
    width: 100%;
  }
}

@keyframes paul-card-anim {
  100% {
    transform: rotate(5deg);
    opacity: 1;
  }
}

@keyframes paul-card-anim2 {
  100% {
    transform: rotate(-5deg);
    opacity: 1;
  }
}

@keyframes chevron {
  to{
    transform: translateY(-1rem);
  }
}